<template>
  <div>
    <page-title :heading="$t('settings.lang_exportTablesQr')"
                :subheading="$t('settings.lang_exportTablesQr')"
                :icon="icon"
    ></page-title>
    <div class="app-main__inner">
      <table-qr-component/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle";

import TableQrComponent from "@/components/settings/cashier/TablePlaner/TableQrComponent";

export default {
  name: "TableQrView",
  components: {
    TableQrComponent,
    PageTitle

  },
  data() {
    return {
      icon: 'pe-7s-monitor icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
