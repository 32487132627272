<template>
  <v-card>
    <v-card-title>
      {{$t('tablebee.lang_qr_code_generator')}}

      <v-spacer></v-spacer>
      <!-- start  bulk export -->
      <v-menu
          bottom
          origin="center center"
          transition="scale-transition"
          v-if="selectedItems.length>0"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              depressed
              class="mx-2"
              fab
              dark
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
              v-for="(item, i) in items"
              :key="i"
          >
            <v-btn
                class="ma-2"
                outlined
                :color="item.color"
                @click="getQRCode(selectedItems.map(item=>{
                        return {UUID:item.uuid}
                      }),item.title.toLocaleLowerCase())"
            >
              <v-icon dark>
                {{ item.icon }}
              </v-icon>
              {{ item.title }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- end  bulk export -->
    </v-card-title>
    <v-divider class="ma-0"/>
    <v-card-text>
      <v-data-table
          :headers="headers"
          :items="tables"
          :search="search"
          v-model="selectedItems"
          :loading="this.loading"
          item-key="name"
          show-select

      >
        <template v-slot:top>
          <v-row>

            <v-col cols="12" sm="6">
              <v-select
                  v-model="selectedRoom"
                  :label="$t('tablebee.lang_select_room')"
                  class="pt-0"
                  :items="room"
                  outlined
                  hide-selected
                  dense
                  item-text="name"
                  item-value="id"

              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="search"
                  :label="$t('tablebee.lang_search_table')"
                  class="mx-4"
                  outlined
                  dense
              ></v-text-field>
            </v-col>
          </v-row>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.png="{ item }">
          <v-btn
              fab
              dark
              depressed
              small
              color="success"
              @click="getQRCode([{UUID:item.uuid}],'png')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.svg="{ item }">
          <v-btn
              fab
              dark
              depressed
              small
              color="warning"
              @click="getQRCode([{UUID:item.uuid}],'svg')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
        <template v-if="selectedItems.length==0" v-slot:item.pdf="{ item }">
          <v-btn
              fab
              dark
              small
              depressed
              color="error"
              @click="getQRCode([{UUID:item.uuid}],'pdf')"
          >
            <v-icon dark>
              mdi-download
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import FileSaver from 'file-saver';

export default {
  name: "TableQrComponent",
  data() {
    return {
      search: '',
      selectedItems: [],
      calories: '',
      room: [],
      selectedRoom: null,
      items: [
        {title: 'PNG', icon: 'mdi-file-image', color: 'warning'},
        {title: 'SVG', icon: 'mdi-file-image', color: 'success'},
        {title: 'PDF', icon: 'mdi-file-pdf', color: 'error'},
      ],
      tablesGlobal: [],
      tables: [],
      loading: false

    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('generic.lang_name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('generic.lang_party'),
          value: 'TotalParties',
        },
        {text: ' PNG', value: 'png', sortable: false, align: 'center'},
        {text: ' SVG', value: 'svg', sortable: false, align: 'center'},
        {text: ' PDF', value: 'pdf', sortable: false, align: 'center'},

      ]
    },
  },
  mounted() {
    this.getRoom();
  },
  watch: {
    selectedRoom(val) {

      if (val)
        this.getTables(val)
    }
  },
  methods: {
    filterOnlyCapsText(value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().indexOf(search) !== -1
    },

    /**
     * get all tables in specific room
     */
    getTables(id) {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.DETAILS, {
        roomID: id
      })
          .then(res => {
            if (res.data.formfillData && res.data.formfillData.textFields) {
              let obj = JSON.parse(res.data.formfillData.textFields.interior_object);
              if (obj.tables) {
                this.tables = obj.tables;
                return
              }
            }
            this.tables = []
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    /**
     * get all available rooms
     */
    getRoom() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.GET_ALL)
          .then(res => {
            if (Array.isArray(res.data.roomInformations)) {
              this.room = res.data.roomInformations.map((room) => ({
                id: room[0],
                name: room[1]
              }));

              this.selectedRoom = this.room[0].id;
            } else this.room = []
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
    getQRCode(table, type) {
      let formData = {}
      let blobType = '';
      let name = '';

      switch (type) {
        case 'png':
          formData.tables = table;
          formData.type = type;
          blobType = (table.length > 1) ? "application/zip" : 'image/png';
          break;
        case 'svg':
          formData.tables = table;
          formData.type = type;
          blobType = (table.length > 1) ? "application/zip" : 'image/svg';
          break;
        case 'pdf':
          formData.tables = table;
          formData.type = type;
          blobType = 'application/pdf';
          break;
      }

      if (table.length > 1 && type !== 'pdf') {
        name = 'localbee_table_QRCODES.zip'
      } else if (table.length > 1 && type === 'pdf') {
        name = 'localbee_tables_QRCODES.pdf'
      } else {
        name = table[0].UUID + '.' + type;
      }
      //console.log(table, type, name);

      this.axios.post(ENDPOINTS.SETTINGS.ROOMPLANER.TABLEQR, formData, {responseType: 'arraybuffer'})
          .then(res => {
            if (res.status === 200) {
              const blobContent = new Blob([res.data], {type: blobType});
              FileSaver.saveAs(new Blob([res.data], {
                type: blobType
              }), name);
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      })

    },
  },
}
</script>

<style scoped>

</style>
